const create = (api) => {
    const createAccess = (username) => {
        return api.post(`/UserManagement/v2/users/apiclient?userName=${username}`)
    }

    const getApiUsers = () => {
        return api.get(`/UserManagement/v2/users/apiusers`)
    }

    return {
        createAccess,
        getApiUsers
    }

    
}

export default { create }