const create = (api) => {

  const getBatteryDischargeRateCard = (siteId,startDate, endDate, pageNumber = 1, pageSize=100) => {
    return api.get(`/dashboard/deviceutilization/batterydischargerate/${siteId}/details?startDate=${startDate}&endDate=${endDate}&includeChildren=true&pageNumber=${pageNumber}&pageSize=${pageSize}&query=&sort=serialNumber&directionSort=1`)
  }


  return {
    getBatteryDischargeRateCard
  }

}

export default {
  create
}
