import { startDate } from "Components/Preferences/components/preferences-email/components/new-report-form/NewReportForm.selector"
import { serialNumber } from "Selectors/DeviceUsageSelector"

const create = (api) => {
    const getBatteryLevelActivity = (deviceId, startDate, endDate, batteryId, siteId) => {
      let batteryString = batteryId == null ? '' : `batteryId=${batteryId}`
      return api.get(`/v2/device/${deviceId}/battery/level?${batteryString}&siteId=${siteId}&startDate=${startDate}&endDate=${endDate}&timeUnit=hour`)
    }

    const getApplicationActivity = (serialNumber, siteId, startDate, endDate, timeUnit) => {
      return api.get(`/dashboard/v1/device/${serialNumber}/application-usage?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}&timeUnit=${timeUnit}`)
    }

    const getApplicationBatteryInformation = (siteId, startDate, endDate, serialNumber, pageNumber, pageSize) => {
      return api.get(`/dashboard/v1/device/${serialNumber}/battery-usage?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&sort=batteryUsage&directionSort=-1`)
    }

    return {
      getApplicationActivity,
      getApplicationBatteryInformation,
      getBatteryLevelActivity
    }

  }

  export default {
    create
  }
