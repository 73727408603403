/* eslint-disable complexity */
import { get, merge, omit, union, without, filter } from 'lodash';
import moment from 'moment';
import { Tooltip } from '@scuf/common';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { createContext, createRef, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { AutoSizer, Column, InfiniteLoader} from 'react-virtualized';
import { useTranslate } from 'react-translate';

import { FormRow, InfoKey, InfoText } from '../../Themes/ScufStyledComponents';
import { ToolsIcon } from './components/actionbar/actionbar.styles';
import { FilterCheckBoxContainer, SectionTitleRow } from './components/filters/filter.styles';
import {
  formFilterSections,
  getFilterCount,
  getFilteredData,
  getFullIndexedData,
  getSearchData,
  getSelectedItems,
  getTableCoreProps,
} from './data-table.helpers';
import {
  FilterColumnContainer,
  FilterColumnTitle,
  FilterContainer,
  FilterCountBadge,
  FilterIcon,
  FilterIconContainer,
  HeaderItem,
  HeaderRow,
  RowBlockItem,
  RowItem,
  Search,
  SearchWrapper,
  SelectionItem,
  StyledCheckbox,
  TableContainer,
  TableRow,
  ToolsActionBarContainer,
  ToolsContainer,
  StyledTable,
  ResizeHandle,
  ResizeHandleIcon,
  HeaderLabel,
  ResizeHandleContainer
} from './data-table.styles';

import {
  ActionBar,
  ActionBarItem,
  FilterItem,
  FilterPanel,
  FilterSection,
  HeaderBar,
  HeaderBarItem,
} from './components';
import Total from './components/total';
import SortIcon from './components/sort-icon';
import headerRenderer from './components/header-label-renderer';

export const DataTableContext = createContext({
  selectedItems: [],
});

const isMobile = window.screen.width < 720;
// TODO: split file renderers and state-binded functions

let getSelectedItemsTypes = {
  data: [],
  selectedItems: [],
  selectPath: '',
  selectionFullItems: false,
  isOnSelectionV2: false,
  isAccessPoint: false,
  exportedData: false,
};

const TranslateTitle = (title) => {
  const translate = useTranslate('Common');
  return <div>{translate(title.title)}</div>;
};
export class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowGetter: ({ index }) => props.data[index],
      rowCount: props.data ? props.data.length : 0,
      rowHeight: props.rowHeight || 48,
      headerHeight: props.headerHeight || 48,
      onRowClick: props.onRowClick ? props.onRowClick : () => null,
      sortBy: props.sortBy || '',
      sortDirection: props.sortDirection,
      selectedItems: props.selectedItems || [],
      data: props.data || [],
      fullData: props.data || [],
      showHeaderAction: '',
      hasNextPage: false,
      filterSections: [],
      unionFilters: {},
      intersectionFilters: props.intersectionFilters,
      onHideColumn: props.onHideColumn ? props.onHideColumn : () => null,
      hiddenColumns: props.hiddenColumns || [],
      searchFilterValue: props.searchFilterValue,
      exportCellData: props.exportCellData,
      titles: props.titles,
      tableWidth: 0,
      columnWidths: [],
      currentTable: ''
    };
    // Renderers
    this._rowRenderer = this._rowRenderer.bind(this);
    this._headerRowRenderer = this._headerRowRenderer.bind(this);
    this._selectionRenderer = this._selectionRenderer.bind(this);
    this._toolsRenderer = this._toolsRenderer.bind(this);
    this._filtersRenderer = this._filtersRenderer.bind(this);
    this._columnFilterRenderer = this._columnFilterRenderer.bind(this);
    this._downloadRenderer = this._downloadRenderer.bind(this);
    // Other functions
    this.onFilterClick = this.onFilterClick.bind(this);
    this.captureFilterClick = this.captureFilterClick.bind(this);
    this.filtersConstructor = this.filtersConstructor.bind(this);
    this.filterSelectionHelper = this.filterSelectionHelper.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this._signalStrengthRender = this._signalStrengthRender.bind(this);
    this._onSearchChanges = this._onSearchChanges.bind(this);
    // Refs
    this.filterContainer = createRef();
    this.infiniteLoaderRef = createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const indexData = getFullIndexedData(props.data);
    const sortedData = getSearchData(
      {
        ...state,
        sortBy: props.sortBy,
        sortDirection: props.sortDirection,
      },
      indexData,
      props.globalFilterValue,
      props.allowDeepSearch
    );
    const filteredData = getFilteredData(sortedData, state.unionFilters, state.intersectionFilters);
    const newState = {
      ...getTableCoreProps(filteredData),
      fullData: indexData,
      selectedItems: props.selectedItems ? props.selectedItems : state.selectedItems,
      filterSections:
        props.generateFilters && props.filterSections ? formFilterSections(indexData, props.filterSections) : [],
      hiddenColumns: props.hiddenColumns || state.hiddenColumns,
      sortBy: props.sortBy,
      sortDirection: props.sortDirection,
    };
    if (props.searchFilterValue !== undefined) {
      newState.searchFilterValue = props.searchFilterValue;
    }

    if (typeof props.onSelectionChange === 'function') {
      getSelectedItemsTypes.data = indexData;
      getSelectedItemsTypes.selectedItems = state.selectedItems;
      getSelectedItemsTypes.selectPath = props.selectPath;
      getSelectedItemsTypes.selectionFullItems = props.selectPath === 'initialIndex' || props.selectionFullItems;
      getSelectedItemsTypes.isOnSelectionV2 = !props.isIndexBasedSelection;
      const selected = getSelectedItems(getSelectedItemsTypes);
      props.onSelectionChange(selected);
    }
    if (state.currentTable != props.assetType){
      newState.currentTable = props.assetType
      newState.columnWidths = []
    }

    return newState;
  }

  componentDidMount() {
    const { selectPath, selectedItems, data } = this.props;
    if (selectedItems && selectPath && !isEmpty(data)) {
      const itemIndex = data.findIndex((item) => selectedItems.includes(get(item, selectPath, '')));
      this.setState({ scrollToIndex: itemIndex });
    }
  }

  _headerRowRenderer({ columns }, width, showTools) {

    const { resizableColumns } = this.props

    const resizeHandler = (deltaX, label) => {
      const { columnWidths } = this.state

      if (columnWidths.filter((column) => column.label.toUpperCase() == label)[0].width + deltaX > 68) {
        const resizedColumnWidths = columnWidths.map((column) => {
          if (column.label.toUpperCase() == label) {
            return { ...column, width: column.width + deltaX }
          }
          return column
        })
        this.setState({
          columnWidths: resizedColumnWidths
        })
      }
      else {
        return false
      }
    }
    return (
      <HeaderRow height={this.props.headerHeight} showTools={showTools} width={width}>
        {columns.map(({ props, key }) => {
            const isSelectionItem = this.props.selection && this.props.selectionMode === 'multiple' && key.includes('Col0')
            const tooltipContent = props['aria-label']?.toUpperCase()
          return isSelectionItem ? (
            <HeaderItem
              key={key}
              {...props}
              isSelectionItem={isSelectionItem} />
          ) : (
            <HeaderItem
              key={key}
              {...props}
            >
              <Tooltip content={<>{tooltipContent}</>} position='bottom center' element={<HeaderLabel {...props}/>} />
              <SortIcon sortDirection={props['aria-sort']}/>
              {
                resizableColumns &&
                  <ResizeHandle
                    onDrag={(event, { deltaX }) => { resizeHandler(deltaX, props.children[0].props.children) }}
                    axis='x'
                    positionOffset={{ x: 20 }}
                  >
                    <ResizeHandleContainer>
                      <ResizeHandleIcon id='resize-handle'>⋮</ResizeHandleIcon>
                    </ResizeHandleContainer>

                  </ResizeHandle>
              }
            </HeaderItem >
          )
        })}
      </HeaderRow>
    );
  }

  _rowRenderer(data, width) {
    const { columns } = data;
    const { selectedItems } = this.state;
    const { onRowClick, selectPath, selection, selectionMode, isEditAsset, isAccessPoint, isIndexBasedSelection, hideRowPointer } =
      this.props;
    const property = get(data.rowData, selectPath, '');
    const selected =
      selectedItems && !isEmpty(selectedItems)
        ? !isIndexBasedSelection
          ? selectedItems.some((s) => s?.id === data?.rowData?.id)
          : selectedItems.includes(property)
        : null;

    const styledData = {
      ...data,
      style: {
        ...data.style,
        width
      }
    }

    return (
      <TableRow
        {
        ...styledData
        }
        className={hideRowPointer ? null : (onRowClick || selection ? 'pointer' : null)}
        onClick={(e) => {
          e.stopPropagation();
          if (onRowClick) {
            onRowClick(data);
          }
          if (selection) {
            if (selectionMode === 'multiple') {
              const newSelected = selected
                ?  filter(selectedItems, (item) => item.id != data.rowData.id)
                :  [...selectedItems, data.rowData];

                typeof this.props.onSelectionV2 === 'function' && this.props.onSelectionV2(newSelected);

                this.setState({
                showHeaderAction: '',
                showActionBar: selectedItems != null && selectedItems.length > 0 ? true : false,
              });
            } else {
              this.setState({
                selectedItems: [property],
                showHeaderAction: '',
                showActionBar: selectPath === 'initialIndex' || !!property,
              });
            }
          }
        }}
        selected={selected}
        isEditAsset={isEditAsset}
      >
        {columns.map(({ props, key }) => {
          const isSelectionItem =
            this.props.selection && this.props.selectionMode === 'multiple' && key.includes('Col0');
          return isSelectionItem ? (
            <RowItem key={key} {...props} isSelectionItem selected={selected} isAccessPoint={isAccessPoint} />
          ) : (
            <RowItem key={key} {...props} selected={selected} isAccessPoint={isAccessPoint}>
              <RowBlockItem {...props} isAccessPoint={isAccessPoint} />
            </RowItem>
          );
        })}
      </TableRow>
    );
  }

  _selectionRenderer() {
    const { data, selectedItems } = this.state;
    const { isIndexBasedSelection } = this.props;
    return (
      <Column
        cellRenderer={({ rowData }) => {
          const { selectedItems } = this.state;
          const { selectPath } = this.props;
          const property = get(rowData, selectPath, '');
          const selected =
            selectedItems && !isEmpty(selectedItems)
              ? !isIndexBasedSelection
                ? selectedItems.some((s) => s?.id === rowData?.id)
                : selectedItems.includes(property)
              : false;
          return (
            <SelectionItem
              onClick={(e) => {
                e.stopPropagation();
                let newSelected;
                if (!isIndexBasedSelection) {
                  newSelected = selected
                    ? filter(selectedItems, (item) => item.id != rowData.id)
                    : [...selectedItems, rowData];
                } else {
                  newSelected = selected ? without(selectedItems, property) : [...selectedItems, property];
                }

                typeof this.props.onSelectionV2 === 'function' && this.props.onSelectionV2(newSelected);

                this.setState({
                  selectedItems: newSelected,
                  showHeaderAction: '',
                  showActionBar: !isEmpty(newSelected),
                });
              }}
            >
              <StyledCheckbox checked={selected} />
            </SelectionItem>
          );
        }}
        dataKey="_selectionKey"
        disableSort
        headerRenderer={() => (
          <SelectionItem>
            <StyledCheckbox
              indeterminate={!!(selectedItems.length && selectedItems.length !== data.length)}
              checked={selectedItems.length && selectedItems.length === data.length}
              onChange={(ch) => {
                const properties = data.map((item) => get(item, this.props.selectPath, ''));
                const selected = ch ? merge(selectedItems, properties) : [ ];
                typeof this.props.onSelectionV2 === 'function' && this.props.onSelectionV2(data, true, ch)
                this.setState({
                  selectedItems: selected,
                  showHeaderAction: '',
                  showActionBar: ch,
                });
              }}
            />
          </SelectionItem>
        )}
        label="_selectionKey"
        width={40}
      />
    );
  }

  _onSearchChanges(searchFilterValue) {
    if (typeof this.props.onSearchChange === 'function') {
      this.props.onSearchChange(searchFilterValue);
    } else {
      this.setState({ searchFilterValue });
    }
  }

  _handleOnToolbarClose = () =>
    this.setState(({ selectedItems }) => ({
      showActionBar: false,
      showHeaderAction: '',
      selectedItems: this.props.keepSelectionOnClose ? selectedItems : [],
    }));

  _toolsRenderer(showTools, [filters]) {
    const {
      search,
      filtersRenderer,
      children,
      selectPath,
      globalFilterValue,
      selectionFullItems,
      totalRows,
      toggleColumns,
      allowDownload,
      hideActionBar,
      searchPlaceholder,
      totalFilters,
      isAccessPoint,
      signalStrengthRender,
    } = this.props;
    const {
      data,
      fullData,
      showActionBar,
      selectedItems,
      searchFilterValue,
      filterSections,
      unionFilters,
      intersectionFilters,
    } = this.state;

    const actionTools = React.Children.toArray(children).filter((c) => get(c, 'props.type', '') === 'ActionBar');
    const headerTools = React.Children.toArray(children).filter((c) => get(c, 'props.type', '') === 'HeaderBar');
    const hasFilters = !!filtersRenderer || !!filters || !isEmpty(filterSections);
    const filterCount = filters
      ? get(filters, 'props.count', 0)
      : getFilterCount(unionFilters) + getFilterCount(intersectionFilters);

    getSelectedItemsTypes.data = fullData;
    getSelectedItemsTypes.selectedItems = selectedItems;
    getSelectedItemsTypes.selectPath = selectPath;
    getSelectedItemsTypes.selectionFullItems = selectPath === 'initialIndex' || selectionFullItems;
    getSelectedItemsTypes.isOnSelectionV2 = false;
    const providerValue = getSelectedItems(getSelectedItemsTypes);

    const isSignalRender = signalStrengthRender;

    return showActionBar && !hideActionBar ? (
      <ToolsActionBarContainer showTools={showTools}>
        <Total totalCount={fullData.length} rowsCount={totalRows} selectedCount={selectedItems.length} />
        <FilterIconContainer display>
          <DataTableContext.Provider value={providerValue}>{actionTools}</DataTableContext.Provider>
          <ToolsIcon close name="close" onClick={this._handleOnToolbarClose} size="small" />
        </FilterIconContainer>
      </ToolsActionBarContainer>
    ) : (
      <ToolsContainer className="no-results" showTools={showTools}>
        {search ? (
          <FormRow alignItems="center">
            <SearchWrapper>
              <Search
                minCharacters={6}
                onSearchChange={this._onSearchChanges}
                placeholder={searchPlaceholder}
                value={searchFilterValue}
                size={searchPlaceholder?.length}
              />
            </SearchWrapper>
            {!isAccessPoint ? (
              <InfoText padding="0.5em">
                {/* TODO: connect to translate */}
                {searchFilterValue || globalFilterValue || filterCount > 0
                  ? `${!isMobile ? 'Showing' : ''} ${data.length} of ${totalRows || fullData.length} Items`
                  : `${totalRows || fullData.length} Items`}
              </InfoText>
            ) : (
              <InfoText padding="0.5em">
                {/* TODO: connect to translate */}
                {searchFilterValue || globalFilterValue || filterCount > 0
                  ? `${!isMobile ? 'Showing' : ''} ${data.length} of ${totalRows || fullData.length} Access points`
                  : `${totalRows || fullData.length} Access points`}
              </InfoText>
            )}
          </FormRow>
        ) : null}
        <FilterIconContainer
          display={!isEmpty(headerTools) || allowDownload || toggleColumns || hasFilters || isSignalRender}
        >
          <DataTableContext.Provider value={providerValue}>{headerTools}</DataTableContext.Provider>
          {allowDownload ? (
            <div>
              <FilterIcon
                className="pointer"
                data-type="action-icon"
                name="export"
                onClick={() => this.onFilterClick('allowDownload')}
                root="building"
                size="medium"
              />
            </div>
          ) : null}
          {toggleColumns ? (
            <div data-type="action-icon">
              <FilterIcon
                className="pointer"
                data-type="action-icon"
                name="toolbar-column-active"
                onClick={() => this.onFilterClick('showColumnFilter')}
                root="common"
                size="medium"
              />
            </div>
          ) : null}
          {hasFilters ? (
            <div data-type="action-icon">
              {totalFilters >= 1 ? (
                <FilterCountBadge data-type="action-icon" onClick={() => this.onFilterClick('showFilters')}>
                  {totalFilters || filterCount}
                </FilterCountBadge>
              ) : null}
              <FilterIcon
                className="pointer"
                data-type="action-icon"
                name="filter1"
                onClick={() => this.onFilterClick('showFilters')}
                root="common"
                size="medium"
              />
            </div>
          ) : null}
          {isSignalRender ? (
            <div data-type="action-icon">
              <FilterIcon
                className="pointer"
                data-type="action-icon"
                name="ellipsis-vertical"
                onClick={() => this.onFilterClick('signalStrength')}
                root="common"
                size="medium"
              />
            </div>
          ) : null}
        </FilterIconContainer>
      </ToolsContainer>
    );
  }

  _columnFilterRenderer(cols) {
    const { onHideColumn } = this.props;
    const { hiddenColumns } = this.state;

    return (
      <FilterColumnContainer>
        <FilterColumnTitle>
          <TranslateTitle title="filterColumns" />
        </FilterColumnTitle>
        {cols.map((col) => {
          const { label, labelKey } = col.props;
          const hidden = hiddenColumns.includes(label);
          return (
            <FilterCheckBoxContainer>
              <StyledCheckbox checked={!hidden} label={label} onChange={() => onHideColumn(labelKey, hidden)} />
            </FilterCheckBoxContainer>
          );
        })}
      </FilterColumnContainer>
    );
  }

  downloadCSV(cols) {
    const { fullData, selectedItems, hiddenColumns } = this.state;
    const { selectPath, tableName, children, count, isAccessPoint, exportedData } = this.props;

    const filteredCols =
      cols ||
      React.Children.toArray(children)
        .filter((c) => get(c, 'props.type', '') === 'Column')
        .filter((col) => !hiddenColumns.includes(col.props.label));

    let csvContent;
    {
      this.state.titles.length > 0
        ? (csvContent = [this.state.titles.map((c) => c).join(',')])
        : (csvContent = [filteredCols.map((c) => c.props.label).join(',')]);
    }

    const selected = isEmpty(selectedItems) ? fullData.map((item) => get(item, selectPath, '')) : selectedItems;

    getSelectedItemsTypes.data = fullData;
    getSelectedItemsTypes.selectedItems = selected;
    getSelectedItemsTypes.selectPath = selectPath;
    getSelectedItemsTypes.selectionFullItems = true;
    getSelectedItemsTypes.isAccessPoint = isAccessPoint;
    getSelectedItemsTypes.exportedData = exportedData;
    getSelectedItemsTypes.isOnSelectionV2 = false;
    getSelectedItems(getSelectedItemsTypes).map((row) => {
      csvContent = [
        ...csvContent,
        filteredCols
          .map((c) => {
            if (typeof this.props.exportCellData === 'function') {
              return this.props.exportCellData(row, c.props);
            } else {
              return row[c.props.dataKey];
            }
          })
          .join(','),
      ];
    });

    const _component = document.createElement('a');
    const csvBlob = new Blob([csvContent.join('\r\n')], {
      type: 'text/csv;charset=utf-8',
      encoding: 'UTF-8',
    });

    _component.download = `${tableName}-${moment()}.csv`;
    _component.href = URL.createObjectURL(csvBlob); // 'data:text/csv;charset=utf-8,' + encodeURI(csvContent.join('\n'))

    _component.click();
  }

  _downloadRenderer(filteredCols) {
    return (
      <FilterColumnContainer>
        <FilterColumnTitle style={{ margin: 0, border: 0 }}>Download</FilterColumnTitle>
        <SectionTitleRow className="pointer" onClick={() => this.downloadCSV(filteredCols)}>
          <InfoKey>CSV</InfoKey>
          <FilterIcon name="document-report" root="common" size="medium" />
        </SectionTitleRow>
        {/* <SectionTitleRow className='pointer' onClick={() => console.log('pdf')}>
          <InfoKey>PDF</InfoKey>
          <FilterIcon
            name='doc-pdf'
            size='medium'
            root='building'
          />
        </SectionTitleRow> */}
      </FilterColumnContainer>
    );
  }

  _signalStrengthRender(signalStrengthRender) {
    return typeof signalStrengthRender === 'function' && signalStrengthRender();
  }

  _filtersRenderer(height, filters, cols, filteredCols) {
    const { showHeaderAction } = this.state;
    const { filtersRenderer, headerHeight, generateFilters, signalStrengthRender, isAccessPoint } = this.props;

    const filterOptions = () => {
      if (filtersRenderer && showHeaderAction === 'showFilters') {
        return filtersRenderer();
      }

      switch (showHeaderAction) {
        case 'showColumnFilter':
          return this._columnFilterRenderer(cols);
        case 'showFilters':
          return generateFilters ? this.filtersConstructor(filtersRenderer) : filters;
        case 'allowDownload':
          return this._downloadRenderer(filteredCols);
        case 'signalStrength':
          return this._signalStrengthRender(signalStrengthRender);
        default:
          return null;
      }
    };

    return showHeaderAction ? (
      <FilterContainer
        height={filtersRenderer ? `${height - headerHeight * 2}px` : 'auto'}
        ref={(e) => (this.filterContainer = e)}
        widthContent={showHeaderAction === 'signalStrength'}
        top={isAccessPoint ? 'unset' : '50px'}
      >
        {filterOptions()}
      </FilterContainer>
    ) : null;
  }

  filtersConstructor(filtersRenderer) {
    const { filterSections, unionFilters, intersectionFilters } = this.state;
    const count = !isEmpty(intersectionFilters) || !isEmpty(unionFilters) || !isEmpty(filtersRenderer);
    return (
      <DataTable.FilterPanel
        count={count}
        onReset={() =>
          this.setState({
            unionFilters: {},
            intersectionFilters: {},
          })
        }
      >
        {filterSections.map((section) => {
          const t = typeof section.i18nMapper === 'function' && section.i18nMapper;
          return (
            <DataTable.FilterPanel.Section key={section.label} label={section.label}>
              {section.filters.map((filter) => {
                const applied =
                  section.type === 'checkbox'
                    ? unionFilters[section.path] && unionFilters[section.path].includes(filter)
                    : intersectionFilters[section.path] === filter;
                return (
                  <DataTable.FilterPanel.Item
                    active={!!applied}
                    key={filter}
                    label={t ? t(filter) : filter}
                    onChange={() => this.filterSelectionHelper(section.type, section.path, filter, applied)}
                    type={section.type}
                  />
                );
              })}
            </DataTable.FilterPanel.Section>
          );
        })}
        {typeof filtersRenderer === 'function' && filtersRenderer()}
      </DataTable.FilterPanel>
    );
  }

  filterSelectionHelper(type, sectionPath, filter, applied) {
    const { unionFilters, intersectionFilters } = this.state;
    if (type === 'checkbox') {
      const newFilter = {
        ...unionFilters,
        [sectionPath]: applied
          ? without(unionFilters[sectionPath], filter)
          : union(unionFilters[sectionPath], [filter]),
      };
      this.setState({
        unionFilters: isEmpty(newFilter[sectionPath]) ? omit(unionFilters, [sectionPath]) : newFilter,
      });
    } else {
      const newFilter = {
        ...intersectionFilters,
        [sectionPath]: applied ? null : filter,
      };
      this.setState({
        intersectionFilters: newFilter[sectionPath] ? newFilter : omit(newFilter, [sectionPath]),
      });
    }
  }

  onFilterClick(stateValue) {
    document.addEventListener('click', this.captureFilterClick);
    this.setState({ showHeaderAction: stateValue });
  }

  captureFilterClick(e) {
    const refNode = ReactDOM.findDOMNode(this.filterContainer);
    if (refNode && !refNode.contains(e.target) && !e.target.getAttribute('data-type')) {
      this.setState({
        showHeaderAction: '',
      });
      document.removeEventListener('click', this.captureFilterClick);
    }
  }

  _handleSortClick = (props) => {
    const { sortBy, sortDirection, event } = props;
    if (event.target.id != 'resize-handle') {
      if (typeof this.props.onSort === 'function') {
        this.setState({ rowCount: 100 });
        this.props.onSort(props);
      }
      this.setState({ sortBy, sortDirection });
    }
  };

  render() {
    const {
      children,
      className,
      search,
      filtersRenderer,
      loadNextPage,
      rows,
      isLoadingPage,
      totalRows,
      selection,
      selectionMode,
      onFilteredColsChanged,
      theme,
      isAccessPoint,
      isAccessPointFooter,
      resizableColumns
    } = this.props;
    const { rowCount, searchFilterValue, hiddenColumns, fullData, columnWidths, tableWidth } = this.state;

    const showTools = search || selection || filtersRenderer;

    const cols = React.Children.toArray(children).filter((c) => get(c, 'props.type', '') === 'Column');
    const filters = React.Children.toArray(children).filter((c) => get(c, 'props.type', '') === 'FilterPanel');


    if (columnWidths.length != cols.length && tableWidth != 0) {
      const initialWidths = cols.map((column) => {
        return {
          label: column.props.label,
          width: column.props.initialWidth != undefined ? column.props.initialWidth : tableWidth / cols.length
        }
      })
      this.setState({ columnWidths: initialWidths })
    }

    const sizedColumns = cols.map((column, index) => {
      const width = (columnWidths.length == cols.length && resizableColumns) ? columnWidths[index].width : column.width
      return React.cloneElement(column, {
        width,
        headerRenderer
      })
    })

    const filteredCols = sizedColumns.filter((col) => !hiddenColumns.includes(col.props.label));
    onFilteredColsChanged(filteredCols);

    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

    let innerTableWidth = 0
    filteredCols.forEach((value) => {
      innerTableWidth += value.props?.width ? value.props?.width : 200
    })

    return (
      <AutoSizer className={className ? `datatable ${className}` : 'datatable'}>
        {({ width, height }) => {
          if (width != tableWidth) { this.setState({ tableWidth: width }) }
          return <InfiniteLoader
            ref={(e) => {
              this.infiniteLoaderRef = e;
            }}
            isRowLoaded={({ index }) => !!fullData[index]}
            loadMoreRows={() => {
              if (!isLoadingPage) {
                const pages = Math.ceil(totalRows / (rows || 1) || 1);
                const currentPage = (Math.floor(rowCount / rows || 1) || 1) + 1;

                if (rowCount > 20 && currentPage <= pages) {
                  loadNextPage(currentPage, rows);
                }
              }
            }}
            rowCount={rowCount < totalRows ? rowCount + 1 : rowCount}
            threshold={rows * 0.5}
          >
            {({ onRowsRendered, registerChild }) => (
              <>
                <TableContainer height={height} width={width + 2} theme={theme}>
                  {this._toolsRenderer(showTools, filters)}
                  {this._filtersRenderer(height, filters, cols, filteredCols)}
                  <StyledTable
                    {...this.state}
                    headerRowRenderer={(d) => this._headerRowRenderer(d, innerTableWidth < width ? width : innerTableWidth, showTools)}
                    height={(showTools ? height - 4 * rem : height) - 2 || 600}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    rowCount={this.state.rowCount}
                    rowRenderer={(data) => this._rowRenderer(data, innerTableWidth < width ? width : innerTableWidth)}
                    sort={this._handleSortClick}
                    width={(innerTableWidth < width ? width : innerTableWidth || 800) - 2}
                  >
                    {selection && selectionMode === 'multiple' ? this._selectionRenderer() : null}
                    {filteredCols}
                  </StyledTable>
                </TableContainer>
                {isAccessPoint && isAccessPointFooter}
              </>
            )}
          </InfiniteLoader>
        }}
      </AutoSizer>
    );
  }
}

DataTable.Column = Column;
DataTable.ActionBar = ActionBar;
DataTable.ActionBar.Item = ActionBarItem;
DataTable.HeaderBar = HeaderBar;
DataTable.HeaderBar.Item = HeaderBarItem;
DataTable.FilterPanel = FilterPanel;
DataTable.FilterPanel.Section = FilterSection;
DataTable.FilterPanel.Item = FilterItem;

DataTable.Column.defaultProps = {
  type: 'Column',
  ...Column.defaultProps,
};

DataTable.propTypes = {
  allowDeepSearch: PropTypes.bool,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  globalFilterValue: PropTypes.string,
  onRowClick: PropTypes.func,
  onHideColumn: PropTypes.func,
  onSearchChange: PropTypes.func,
  scrollHeight: PropTypes.string,
  scrollable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  selectPath: PropTypes.string,
  selectedItems: PropTypes.array,
  selection: PropTypes.bool,
  selectionMode: PropTypes.oneOf(['single', 'multiple']),
  selectionFullItems: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
  onSelectionChange: PropTypes.func,
  selectionWidth: PropTypes.number,
  search: PropTypes.bool,
  error: PropTypes.bool,
  loadNextPage: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  generateFilters: PropTypes.bool,
  filterSections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['checkbox', 'radio']),
    })
  ),
  toggleColumns: PropTypes.bool,
  tableName: PropTypes.string,
  intersectionFilters: PropTypes.object,
  searchFilterValue: PropTypes.string,
  keepSelectionOnClose: PropTypes.bool,
  isIndexBasedSelection: PropTypes.bool,
};

DataTable.defaultProps = {
  allowDeepSearch: false,
  data: [],
  onFilteredColsChanged: () => null,
  selection: false,
  selectionMode: 'multiple',
  selectPath: 'initialIndex',
  search: false,
  sortDirection: 'DESC',
  totalRows: 0,
  rows: 1000,
  loadNextPage: () => null,
  error: false,
  isLoadingPage: false,
  generateFilters: false,
  selectionWidth: 40,
  toggleColumns: false,
  tableName: '',
  intersectionFilters: {},
  searchFilterValue: undefined,
  headerHeight: 32,
  rowHeight: 32,
  titles: [],
  onSelectionV2: (ll) => { },
  isIndexBasedSelection: true,
};

DataTable.Column.defaultProps = {
  type: 'Column',
  ...Column.defaultProps,
};
