const create = (api) => {

  const batteryDischargerDevice = (siteId, startDate, endDate, serialNumber) => {
    return api.get(`/dashboard/deviceutilization/batterydischargerate/${serialNumber}?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
  }

  const batteryDischargerOrganization = (startDate, endDate, siteId) => {
    if(siteId) {
      return api.get(`/dashboard/deviceutilization/batterydischargerate?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}&includeChildren=true`)
    } else {
      return api.get(`/dashboard/deviceutilization/batterydischargerate?startDate=${startDate}&endDate=${endDate}`)
    }
    
  }

  return {
    batteryDischargerDevice,
    batteryDischargerOrganization
  }

}

export default {
  create
}
