import AlertsService from './AlertsService';
import api from './ApiSauceInstance';
import BatteryService from './BatteryService';
import BiReportService from './BiReportService';
import { BulkProvisionService } from './bulk-provision-service';
import DashboardService from './DashboardService';
import { DeviceService as TypeService } from './device-service';
import DeviceService from './DeviceService';
import { FilesService } from './files-service';
import LocationService from './LocationService';
import LoginService from './LoginService';
import LogOutService from './LogOutService';
import MarketplaceService from './MarketplaceService';
import Logservice from './LogService';
import PreferencesService from './PreferencesService';
import CounterService from './CounterService';
import SiteComparisonService from './SiteComparisonService';
import ReportService from './ReportService';
import SitesManagerService from './SitesManagerService';
import { TagsService } from './tags-service';
import { PermissionsService } from './permissions-service';
import UserManagerService from './UserManagerService';
import UserService from './UserService';
import UserSettingsService from './UserSettingsService';
import { OrganizationsService } from '../features/organization-management/service';
import { SitesService } from '../features/site-management/service';
import RolesService from './roles-service';
import InventoryService from './InventoryService';
import RemoteAccessService from './RemoteAccessService';
import { MaintenanceService } from './maintenance-service';
import { NetworkRangesService } from '../features/network-ranges/service';
import DomainService from './DomainService';
import { ContactTracingService } from '../features/contact-tracing-report/service';
import { ReleaseNotesService } from './release-notes';
import FloorPlansService from './FloorPlansService';
import BeaconsService from './BeaconsService';
import LTLTripService from '../features/ltl-trip-management/service/LTLTripService';
import { SoftwareUpdateService } from 'features/software-update/service/software-service';
import OperationsService from './OperationsService';
import DeviceActionsService from './DeviceActionsService';
import SubscriptionsService from './SubscriptionsService';
import { CheckOutInService } from '../features/check-out-in/service';
import DeviceLogsService from './DeviceLogservice';
import { SsoOauthService } from 'features/sso-oauth2/api/sso-oauht.service';
import AutomationService from './AutomationService';
import AccessPointsService from './AccessPointsService';
import { OrgInternalReportingDashboardService } from '../../src/features/org-internal-reporting/service';
import ApplicationUsageService from './ApplicationUsageService';
import RevokeTokenService from './RevokeTokenService';
import ReportDownloadsService from './ReportDownloadsService';
import RenewTokenService from './RenewTokenService';
import ObjectStorageService from './ObjectStorageService';
import DataHubService from './DataHubService';
import SubscriptionManagementService from 'features/SubscriptionManagement/Service/SubscriptionManagementService';
import BatteryDischargerDeviceService from 'features/BatteryDischargeRate/Services/BatteryDischargeRateService';
import ApplicationReportService from 'features/ApplicationReport/Service/ApplicationReportService';
import ApplicationActivityService from '../Containers/AssetsDetails/components/asset-battery/Services/ApplicationActivityService'
import BatteryUsageService from 'Containers/AssetsDetails/components/asset-battery/Services/ApplicationActivityService';
import BatteryDischargeRateCardService from 'features/v2/Dashboard/BatteryHealth/BatteryDischargeRate/Service/BatteryDischargeRateService';
import CredentialManagerService from 'features/SubscriptionManagement/Features/CredentialManager/Service/CredentialManagerService';
import AnomalyOverviewService from 'features/v2/AnomalyOverview/Service/AnomalyOverviewService';
import MySoftwareService from 'features/v2/SoftwareUpdates/MySoftware/Service/MySoftwareService';

export const RevokeTokenAPI = RevokeTokenService.create(api);
export const LoginApi = LoginService.create();
export const UserApi = UserService.create(api);
export const DeviceApi = DeviceService.create(api);
export const UserManagerApi = UserManagerService.create(api);
export const SitesManagerApi = SitesManagerService.create(api);
export const LogOutApi = LogOutService.create();
export const BatteryApi = BatteryService.create(api);
export const ReportApi = ReportService.create(api);
export const LocationApi = LocationService.create(api);
export const BiReportApi = BiReportService.create(api);
export const DashboardApi = DashboardService.create(api);
export const AlertsApi = AlertsService.create(api);
export const PreferencesApi = PreferencesService.create(api);
export const UserSettingsApi = UserSettingsService.create(api);
export const OrganizationsApi = new OrganizationsService(api);
export const SitesApi = new SitesService(api);
export const NetworkRangeApi = new NetworkRangesService(api);
export const TypeApi = new TypeService(api);
export const FilesApi = new FilesService(api);
export const TagsApi = new TagsService(api);
export const BulkProvisionApi = new BulkProvisionService(api);
export const MarketplaceApi = MarketplaceService.create(api);
export const LogApi = Logservice.create(api);
export const SiteComparisonApi = SiteComparisonService.create(api);
export const CounterApi = CounterService.create(api);
export const RolesApi = new RolesService(api);
export const PermissionsApi = new PermissionsService(api);
export const InventoryApi = InventoryService.create(api);
export const RemoteAccessApi = RemoteAccessService.create();
export const MaintenanceApi = new MaintenanceService(api);
export const DomainApi = DomainService.create();
export const ContactTracingApi = new ContactTracingService(api);
export const ReleaseNoteApi = new ReleaseNotesService(api);
export const FloorPlansApi = FloorPlansService.create(api);
export const BeaconsApi = BeaconsService.create(api);
export const LTLTripApi = LTLTripService.create(api);
export const SoftwareUpdateApi = new SoftwareUpdateService(api);
export const SubscriptionManagementApi = SubscriptionManagementService.create(api)
export const BatteryDischargerDeviceApi = BatteryDischargerDeviceService.create(api)
export const OperationsApi = OperationsService.create(api);
export const DeviceActionsApi = DeviceActionsService.create(api);
export const SubscriptionsApi = SubscriptionsService.create(api);
export const CheckOutInApi = new CheckOutInService(api);
export const DeviceLogsApi = DeviceLogsService.create(api);
export const SsoOauthApi = new SsoOauthService(api);
export const AutomationApi = AutomationService.create(api);
export const AccessPoints = AccessPointsService.create(api);
export const OrgInternalReportingApi = new OrgInternalReportingDashboardService(api);
export const ApplicationUsageExportApi = ApplicationUsageService.create(api);
export const ReportDownloadsApi = ReportDownloadsService.create(api);
export const RenewTokenApi = RenewTokenService.create(api);
export const ObjectStorageApi = ObjectStorageService.create(api)
export const DataHubServiceApi = DataHubService.create(api)
export const ApplicationReportApi = ApplicationReportService.create(api)
export const ApplicationActivityApi = ApplicationActivityService.create(api)
export const BatteryUsageApi = BatteryUsageService.create(api)
export const BatteryDischargeRateCardApi = BatteryDischargeRateCardService.create(api)
export const CredentialManagerApi = CredentialManagerService.create(api)
export const AnomalyOverviewApi = AnomalyOverviewService.create(api)
export const MySoftwareApi = MySoftwareService.create(api)
