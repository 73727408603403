import { Header, Select, SidebarLayout } from '@scuf/common';
import UserProfile from '@scuf/common/dist/components/Header/UserProfile/UserProfile';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-translate';
import styled, { keyframes, ThemeProvider } from 'styled-components/macro';

import AppVersionBanner from 'Components/app-version-banner';
import Breadcrumb from 'Components/Breadcrumb';
import Footer from 'Components/footer';
import StyledHeader from 'Components/header-logo';
import HelpMenu from 'Components/help-menu';
import Loader from 'Components/Loader';
import LoginAuthHonSSO from 'Components/LoginSection/components/LoginAuthSSO';
import MenuOptions from 'Components/MenuOptions';
import Modal from 'Components/Modal';
import BellNotification from 'Components/NotificationBell';
import TranslationsSwitch from 'Components/TranslationsSwitch';
import config from 'Config/AppConfig';
import { SsoOauthActions } from 'features/sso-oauth2';
import { createRedirectOauthUrl } from 'features/sso-oauth2/utils/redirect-util';
import DashbordActions from 'Redux/DashboardRedux';
import LoginActions from 'Redux/LoginRedux';
import ModalActions from 'Redux/ModalRedux';
import UserActions from 'Redux/UserRedux';
import UserSettingsAcctions from 'Redux/UserSettingsRedux';
import { generalLoadingSelector } from 'Selectors/LoaderSelector';
import { RevokeTokenAPI, UserApi } from 'Services';
import { devicesFilter } from 'Utils/constants';
import { isMobile, isMobilexs, MobileComponent } from 'Utils/constants';
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags';
import { withPermissions } from 'Utils/permissions';

import { BodyWrapper } from './AppFrame.styles';
import OrganizationChange from './OrganizationChange';

const home = config.site.landing;

const AppFrameContainer = styled.section`
  height: 100vh;
  overflow: hidden;
`;

const collapse = keyframes`
  from {
    width: 'calc(100vw - 3.5rem)'
  }
  to {
    width: 'calc(100vw - 14.25rem)'
  }
`;

const noCollapse = keyframes`
  from {
    width: 'calc(100vw - 14.25rem)'
  }
  to {
    width: 'calc(100vw - 3.5rem)'
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  height: ${({ noHeader }) => (noHeader ? '100vh' : isMobile() ? '90vh' : '90vh')};
  animation: ${({ collapsed, noHeader }) => (collapsed && !noHeader ? collapse : noCollapse)} 1s linear infinite;
  height: ${({ noHeader }) => (noHeader ? '95vh' : '90vh')};
    ? '100vh'
    : isMobile()
      ? '81vh'
      : '90vh'};

  @media (max-width: 768px) {
    width: 100vw !important;
    height: 100vh !important;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 1rem;
  & .text {
    width: 94%;
    text-align: left;
    font-size: 0.75rem !important;
  }

  & .ui.selection.dropdown {
    height: 50%;
    width: 90%;
    max-height: 100% !important;
    min-height: 0px !important;
    min-width: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  & .ui.disabled.dropdown {
    margin-top: 5%;
    height: 50%;
    width: 90%;
    max-height: 100% !important;
    min-height: 0px !important;
    min-width: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    border: #d0d0d0;
  }

  & .ui.active.visible.selection.dropdown .item {
    justify-content: flex-start !important;
    border-bottom: 1px solid #f0f0f0 !important;
    font-size: 0.75rem !important;
  }
  & .ui.active.visible.selection.dropdown .item:hover {
    background: #f7f7f7 !important;
  }
  & .ui.active.visible.selection.dropdown .item:last-child {
    border: none !important;
  }
  & .ui.active.visible.selection.dropdown {
    border: #d0d0d0;
  }
`;

const TitleStyled = styled.p`
  font-size: 0.75rem;
  margin: 0 0.25rem 0 0;
  padding: 0;
  font-weight: 400;
  color: #606060;
  font-family: Honeywell Sans Web, Arial, Helvetica, sans-serif;
`;


const Label = styled.label`
  font-size: 0.8rem;
`;
const Labelxs = styled(Label)``;
const LogoutLabel = styled(Label)`
  color: ${(props) => props.theme.h1color};
  cursor: pointer;
`;
const Badge = styled.div`
  @media (max-width: 760px) {
    display: ${(props) => (props.display ? 'flex' : 'none')};
    width: 8px;
    height: 8px;
    background-color: #be271c;
    border-radius: 25px;
    top: 7px;
    position: absolute;
    right: 6px;
  }
`;

const StyledDiv = styled.div`
  pointer-events: ${(props) => props.pointerEvent || 'auto'};
  display: flex;
`;
let pointerValue = 'auto';

const BetaTitle = (
  <React.Fragment>
    <label> Op. Intel </label>
    {isMobilexs ? <Label>ß</Label> : <Labelxs>ßeta</Labelxs>}
  </React.Fragment>
);

const SubscriptionText = styled.span`
  font-style: italic;
  font-size: ${({ theme }) => theme?.typography?.h4?.size ?? '0.875rem'};
  line-height: ${({ theme }) => theme?.typography?.h4?.lineHeight ?? '1.375rem'};
  color: ${({ theme }) => theme?.colors?.buttonPrimary ?? '#1274B7'};
`;

class AppFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.getMenuCollapse(),
      userName: 'Default',
      modal: false,
      hideHeader: false,
      url: '',
      hideMenu: false,
      ossOpen: false,
      isOrgLoading: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.changeOrgForSso = this.changeOrgForSso.bind(this);
    this._logout = this._logout.bind(this);
    this.revokeToken = this.revokeToken.bind(this);
  }

  componentDidMount() {
    if (window.location.search.includes('hide-header=true')) {
      this.setState({ hideHeader: true });
    }
    if (window.location.search.includes('hide-menu=true')) {
      this.setState({ hideMenu: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOrgId !== this.props.selectedOrgId) {
      this.props.changeContentVisibility();
    }
  }

  changeOrgForSso(orgId, params) {
    const { history } = this.props;
    this.props.setCurrentRoute(history.location.pathname);
    this.props.setOrganizationforSso(orgId);
    const redirectUrl = createRedirectOauthUrl(params);
    window.location.replace(redirectUrl);
  }

  async changeOrg(value) {
    const { userId, history, idToken } = this.props;
    this.setState({ isOrgLoading: true });
    const resp = await RevokeTokenAPI.revokeToken();
    if (!resp.ok) {
      console.error(':::Token revoke API Failed');
    }
    if (idToken) {
      this.props.changeOrgId(value);
      this.props.onToken(idToken, this.props.headerToken, value, false);
      this.props.closeDrillDown();
      this.props.changeContentVisibility();
      devicesFilter.map(async (type) => {
        await UserApi.postFilters({ section: type, search: '', userId: userId });
      });
      history.push(history.location.pathname);
    } else {
      this.changeOrgForSso(value, this.props.ssoOauth);
    }
  }

  getMenuCollapse() {
    let collapsed = false;
    if (global.localStorage) {
      collapsed = JSON.parse(global.localStorage.getItem('menuCollapse')) || false;
    }
    collapsed = collapsed || window.location.pathname.includes('/execDashboard');
    return collapsed;
  }

  setMenuCollapse(collapse) {
    if (global.localStorage) {
      global.localStorage.setItem('menuCollapse', JSON.stringify(collapse));
    }
  }

  toggleMenu() {
    const collapsed = !this.state.collapsed;
    this.setState({ collapsed });
    this.setMenuCollapse(collapsed);
  }

  toggleModal(show = false) {
    this.setState({ modal: show });
  }

  translationOptions() {
    const { flags } = this.props;

    if (flags.multilanguage) {
      return (
        <Header.Item href="#">
          <TranslationsSwitch theme={this.props.theme} />
        </Header.Item>
      );
    }

    return null;
  }

  handleCollapse() {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  renderTitle() {
    const { t, subscriptionName, flags } = this.props;

    if (!subscriptionName) {
      pointerValue = 'none';
    } else {
      pointerValue = 'auto';
    }

    if (flags.premiumLevelDefault) {
      return <span>{t('AppFrame_Header')}</span>;
    } else {
      return (
        <span>
          {t('AppFrame_Header')}
          {subscriptionName && <SubscriptionText> {subscriptionName}</SubscriptionText>}
        </span>
      );
    }
  }

  async revokeToken() {
    const resp = await RevokeTokenAPI.revokeToken();
    if (!resp.ok) {
      console.error(':::Token revoke API Failed');
    }
  }

  _logout() {
    this.revokeToken();
    this.props.history.push('/logout');
  }

  render() {
    // eslint-disable-next-line
    const { collapsed, hideHeader, hideMenu, isOrgLoading } = this.state;
    const disableFooter = window.location.pathname.split('/')[1] == 'counter' ? true : false;
    const {
      loading,
      flags,
      isAdmin,
      isDeviceAdmin,
      t,
      userRole,
      userFirstName,
      openModal,
      theme,
      userId,
      organizations,
      permissions,
      history,
      selectedOrgId,
      hiddenContent,
    } = this.props;
    const enableBreadcrumb =
      window.location.pathname !== '/dashboard/sites' &&
      window.location.pathname !== '/dashboard/sites/batteryExpectedRuntimeDetails'  &&
      window.location.pathname !== '/dashboard/sites/batteryDischargeRateDetails' &&
      window.location.pathname !== '/anomaly/overview' &&
      window.location.pathname !== '/anomaly/overview-detail' &&
      window.location.pathname !== '/reports/tools' ? (
        <Breadcrumb />
      ) : null;

    return (
      <AppFrameContainer>
        <LoginAuthHonSSO />
        {!hideHeader ? (
          <ThemeProvider theme={theme}>
            <BodyWrapper>
              <StyledHeader
                menu={!hideMenu}
                onMenuToggle={this.toggleMenu}
                title={isMobile() ? BetaTitle : this.renderTitle()}
              >
                {organizations.length > 1 && !isMobile() && (
                  <>
                    <TitleStyled>{`${t('AppFrame_Organization')}:`}</TitleStyled>
                    <StyledSelect
                      disabled={hiddenContent}
                      options={organizations}
                      value={selectedOrgId}
                      onChange={(value) => this.changeOrg(value)}
                      placeholder=""
                      search={true}
                    />
                  </>
                )}
                <StyledDiv pointerEvent={pointerValue}>
                  {flags.notificationBell && permissions['alert:read'] && <BellNotification />}
                  <MobileComponent isMobile={!isMobile()}>
                    <HelpMenu />
                  </MobileComponent>
                  <UserProfile firstName={userFirstName}>
                    {flags.profileMenuOption && (
                      <MobileComponent isMobile={!isMobile()}>
                        <UserProfile.Item onClick={() => history.push('/admin/user_profile')}>Profile</UserProfile.Item>
                      </MobileComponent>
                    )}
                    {flags.settingsMenuOption && (
                      <MobileComponent isMobile={!isMobile()}>
                        <UserProfile.Item id="settings"
                          onClick={() => openModal('settingsModal', { size: 'small', closeIcon: true })}
                        >
                          {t('AppFrame_Settings')}
                        </UserProfile.Item>
                      </MobileComponent>
                    )}
                    {permissions['notifications:enabled'] && (
                      <MobileComponent isMobile={!isMobile()}>
                        <UserProfile.Item
                          className="user-profile-preferences" id="preferences"
                          onClick={() => history.push('/preferences')}
                        >
                          {t('AppFrame_Preferences')}
                        </UserProfile.Item>
                      </MobileComponent>
                    )}
                    {flags.subscriptionView &&
                      (
                        <UserProfile.Item id="subscription"
                          onClick={() => history.push('/subscription')}
                        >
                          {t('Subscription')}
                        </UserProfile.Item>
                      )
                    }
                     {flags.subscriptionManagement &&
                      (
                        <UserProfile.Item id="subscriptionManagement"
                          onClick={() => history.push('/subscriptionManagement')}
                        >
                          {t('AppFrame_SubscriptionManagement')}
                        </UserProfile.Item>
                      )
                    }
                    <UserProfile.Item id="logout" onClick={this._logout}>
                      {t('AppFrame_Logout')}
                    </UserProfile.Item>
                  </UserProfile>
                  {this.translationOptions()}
                </StyledDiv>
              </StyledHeader>
              <Badge display={flags.notificationBell} />
              <SidebarLayout collapsed={collapsed} noIcons={false}>
                <MenuOptions
                  userId={userId}
                  isAdmin={isAdmin}
                  isDeviceAdmin={isDeviceAdmin}
                  userRole={userRole}
                  collapsed={collapsed}
                />
                <SidebarLayout.Content>
                  <Loader loading={loading} />
                  <AppVersionBanner />
                  {enableBreadcrumb}
                  {!hiddenContent ? (
                    <ContentWrapper collapsed={collapsed} hidden={hiddenContent}>
                      {this.props.children}
                    </ContentWrapper>
                  ) : (
                    <OrganizationChange />
                  )}
                </SidebarLayout.Content>
              </SidebarLayout>
              <Footer isFooterDisabled={disableFooter} />
            </BodyWrapper>
          </ThemeProvider>
        ) : (
          <BodyWrapper>
            <Loader loading={loading} />
            {enableBreadcrumb}
            <ContentWrapper noHeader>{this.props.children}</ContentWrapper>
          </BodyWrapper>
        )}
        <Modal />
      </AppFrameContainer>
    );
  }
}

AppFrame.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  flags: flagsPropTypes,
  isAdmin: PropTypes.bool,
  userRole: PropTypes.string,
  logOut: PropTypes.func,
  auth: PropTypes.func,
  loggedIn: PropTypes.bool,
  userName: PropTypes.string,
  userFirstName: PropTypes.string,
  error: PropTypes.string,
  t: PropTypes.func,
  openModal: PropTypes.func,
  theme: PropTypes.object,
};

AppFrame.defaultProps = {
  error: '',
  flags: defaultFlags,
  isAdmin: false,
  isHoneywellAdmin: false,
  openModal: () => null,
  t: () => null,
  userFirstName: '',
  userName: 'Default',
  theme: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal, props) => dispatch(ModalActions.modalOpen(modal, props)),
    onToken: (token, headerToken, orgId, firstTimeLogin) =>
      dispatch(LoginActions.loginSignInRequest(token, headerToken, orgId, firstTimeLogin)),
    changeOrgId: (orgId) => dispatch(LoginActions.loginSetTransitionOrganization(orgId)),
    getSites: () => dispatch(UserActions.userDetailsRequest()),
    changeContentVisibility: () => dispatch(UserSettingsAcctions.changeContentVisibility()),
    closeDrillDown: () => dispatch(DashbordActions.dashboardCloseDrilldown()),
    setCurrentRoute: (path) => dispatch(SsoOauthActions.setRedirectRoute(path)),
    setOrganizationforSso: (orgId) => dispatch(SsoOauthActions.changeOrganization(orgId)),
  };
};

export default pipe(
  translate('AppFrame'),
  withLDConsumer(),
  withRouter,
  connect(generalLoadingSelector, mapDispatchToProps),
  withPermissions()
)(AppFrame);
